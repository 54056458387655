
import { EventEmitter } from 'events';

export class CastSenderEvent {
  constructor(type) {
    this.type = type;
  }
}

export class CastSenderEventTarget extends EventEmitter {
  addEventListener(eventType, eventHandler) {
    this.on(eventType, eventHandler);
  }

  removeEventListener(eventType, eventHandler) {
    this.off(eventType, eventHandler);
  }

  dispatchEvent(event) {
    this.emit(event.type, event);
  }
}