import {
  CastSenderEventTarget
} from './events/castSenderEvent';
import {
  ConnectedConnectionStateEvent,
  ConnectingConnectionStateEvent,
  DisconnectedConnectionStateEvent,
  DisconnectingConnectionStateEvent
} from './events/connectionState';
import {
  CastSenderDevice,
  CastSenderDevicesAvailableEvent,
  CastSenderDevicesUpdateEvent,
  CastSenderNoDevicesEvent
} from './events/devices';
import {
  BufferingStateCastSenderEvent,
  IdleStateCastSenderEvent,
  PausedStateCastSenderEvent,
  PlayingStateCastSenderEvent
} from './events/state';
import {
  CastSenderAudioTrack,
  CastSenderAudioTracksUpdateEvent,
  CastSenderTextTrack,
  CastSenderTextTracksUpdateEvent
} from './events/tracks';
import {
  CastSenderCustomMessageReceivedEvent,
  CastSenderMediaInformationUpdateEvent,
  CastSenderPositionUpdateEvent,
  CastSenderVolumeUpdateEvent
} from './events/video';

export default class Sender extends CastSenderEventTarget {
  constructor({ logger, customChannelNamespace }) {
    super();

    this._logger = logger.forName('Sender');
    this._customChannelNamespace = customChannelNamespace;
    this._cast = null;
    this._chrome = null;

    this._castContext = null;

    this._remotePlayer = null;
    this._remotePlayerController = null;

    this._castSession = null;
    this._mediaSession = null;
  }

  _initCastContext(receiverApplicationId) {
    const { CastContextEventType, CastContext } = this._cast.framework;

    if (this._castContext) {
      this._stopListenCastContextEvents(this._castContext, CastContextEventType);
    }

    this._castContext = CastContext.getInstance();
    this._startListenCastContextEvents(this._castContext, CastContextEventType);

    this._castContext.setOptions({
      autoJoinPolicy: this._chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
      resumeSavedSession: true,
      receiverApplicationId,
    });
  }

  _initRemotePlayer() {
    const { RemotePlayer, RemotePlayerController, RemotePlayerEventType } = this._cast.framework;

    if (this._remotePlayerController) {
      this._stopListenRemotePlayerControllerEvents(this._remotePlayerController, RemotePlayerEventType);
    }

    this._remotePlayer = new RemotePlayer();
    this._remotePlayerController = new RemotePlayerController(this._remotePlayer);
    this._startListentRemotePlayerControllerEvents(this._remotePlayerController, RemotePlayerEventType);
  }

  _initCastSession(castSession) {
    const { SessionEventType } = this._cast.framework;

    if (this._castSession) {
      this._stopListenCastSessionEvents(this._castSession, SessionEventType);
      this._stopListenMessages(this._castSession, { customChannel: this._customChannelNamespace });
    }

    this._castSession = castSession;
    this._startListenToCastSessionEvents(this._castSession, SessionEventType);
    this._startListenToMessages(this._castSession, { customChannel: this._customChannelNamespace });
  }

  _initMediaSession(mediaSession) {
    //probably, all events from media session are accessible from remote player controller interface
    if (this._mediaSession) {
      this._stopListenToMediaSessionEvents(this._mediaSession);
    }

    this._mediaSession = mediaSession;
    this._startListenToMediaSessionEvents(this._mediaSession);
  }

  _stopListenToMediaSessionEvents(mediaSession) {
    mediaSession.removeUpdateListener(this._onMediaSessionUpdate);
  }

  _startListenToMediaSessionEvents(mediaSession) {
    mediaSession.addUpdateListener(this._onMediaSessionUpdate);
  }

  _onMediaSessionUpdate = (isAlive) => {
    if (!isAlive) {
      return;
    }

    //As part of fix for FLUXUI 54622 adding conditional check in paused state to update CD progress position
    const { PAUSED } = this._chrome.cast.media.PlayerState;
    const playerState = this._mediaSession.playerState;
    if (playerState == PAUSED && this._remotePlayer != null && this._remotePlayer.isPaused) {
      const currentTime = this._mediaSession.getEstimatedTime() ?? this._mediaSession.currentTime;

      if (currentTime == this._remotePlayer.currentTime) {
        this._logger.log('Updating the playback position in CD: ', currentTime);
        this.dispatchEvent(new CastSenderPositionUpdateEvent(currentTime));
      }
    }

    const activeTrackIds = this._mediaSession.activeTrackIds || [];
    const tracks = this._mediaSession.media.tracks || [];

    const audioTracks = tracks
      .filter((track) => track.type === this._chrome.cast.media.TrackType.AUDIO)
      .map((track) => new CastSenderAudioTrack(
        track.trackId,
        track.language,
        activeTrackIds.includes(track.trackId),
        track.name,
        track.customData ? track.customData.isAudioDescription : false,
        track.customData ? track.customData.isAdBreakTrack : false,
      ));

    this.dispatchEvent(new CastSenderAudioTracksUpdateEvent(audioTracks));

    const textTracks = tracks
      .filter((track) => track.type === this._chrome.cast.media.TrackType.TEXT)
      .map((track) => new CastSenderTextTrack(
        track.trackId,
        track.language,
        activeTrackIds.includes(track.trackId),
        track.name,
        track.customData ? track.customData.isHardOfHearing : false,
        track.customData ? track.customData.isAdBreakTrack : false,
      ));

    this.dispatchEvent(new CastSenderTextTracksUpdateEvent(textTracks));
  }

  _stopListenCastContextEvents(castContext, {
    CAST_STATE_CHANGED,
    SESSION_STATE_CHANGED,
  }) {
    castContext.removeEventListener(CAST_STATE_CHANGED, this._onCastStateChanged);
    castContext.removeEventListener(SESSION_STATE_CHANGED, this._onCastSessionStateChanged);
  }

  _startListenCastContextEvents(castContext, {
    CAST_STATE_CHANGED,
    SESSION_STATE_CHANGED,
  }) {
    castContext.addEventListener(CAST_STATE_CHANGED, this._onCastStateChanged);
    castContext.addEventListener(SESSION_STATE_CHANGED, this._onCastSessionStateChanged);
  }

  _stopListenRemotePlayerControllerEvents(remoteController, {
    VOLUME_LEVEL_CHANGED,
    CURRENT_TIME_CHANGED,
    PLAYER_STATE_CHANGED,
    MEDIA_INFO_CHANGED,
  }) {
    remoteController.removeEventListener(VOLUME_LEVEL_CHANGED, this._onRemotePlayerVolumeChange);
    remoteController.removeEventListener(CURRENT_TIME_CHANGED, this._onRemotePlayerCurrentTimeChange);
    remoteController.removeEventListener(PLAYER_STATE_CHANGED, this._onRemotePlayerStateChange);
    remoteController.removeEventListener(MEDIA_INFO_CHANGED, this._onRemotePlayerMediaInfoChange);
  }

  _startListentRemotePlayerControllerEvents(remoteController, {
    VOLUME_LEVEL_CHANGED,
    CURRENT_TIME_CHANGED,
    PLAYER_STATE_CHANGED,
    MEDIA_INFO_CHANGED,
  }) {
    remoteController.addEventListener(VOLUME_LEVEL_CHANGED, this._onRemotePlayerVolumeChange);
    remoteController.addEventListener(CURRENT_TIME_CHANGED, this._onRemotePlayerCurrentTimeChange);
    remoteController.addEventListener(PLAYER_STATE_CHANGED, this._onRemotePlayerStateChange);
    remoteController.addEventListener(MEDIA_INFO_CHANGED, this._onRemotePlayerMediaInfoChange);
  }


  _stopListenCastSessionEvents(castSession, {
    APPLICATION_STATUS_CHANGED,
    APPLICATION_METADATA_CHANGED,
    ACTIVE_INPUT_STATE_CHANGED,
    VOLUME_CHANGED,
    MEDIA_SESSION,
  }) {
    castSession.removeEventListener(APPLICATION_STATUS_CHANGED, this._onApplicationStatusChanged);
    castSession.removeEventListener(APPLICATION_METADATA_CHANGED, this._onApplicationMetadataChanged);
    castSession.removeEventListener(ACTIVE_INPUT_STATE_CHANGED, this._onApplicationActiveInputStateChanged);
    castSession.removeEventListener(VOLUME_CHANGED, this._onCastSessionVolumeChanged);
    castSession.removeEventListener(MEDIA_SESSION, this._onMediaSessionReceived);
  }

  _startListenToCastSessionEvents(castSession, {
    APPLICATION_STATUS_CHANGED,
    APPLICATION_METADATA_CHANGED,
    ACTIVE_INPUT_STATE_CHANGED,
    VOLUME_CHANGED,
    MEDIA_SESSION,
  }) {
    castSession.addEventListener(APPLICATION_STATUS_CHANGED, this._onApplicationStatusChanged);
    castSession.addEventListener(APPLICATION_METADATA_CHANGED, this._onApplicationMetadataChanged);
    castSession.addEventListener(ACTIVE_INPUT_STATE_CHANGED, this._onApplicationActiveInputStateChanged);
    castSession.addEventListener(VOLUME_CHANGED, this._onCastSessionVolumeChanged);
    castSession.addEventListener(MEDIA_SESSION, this._onMediaSessionReceived);
  }

  _startListenToMessages(castSession, namespaces) {
    castSession.addMessageListener(namespaces.customChannel, this._onCustomChannelMessageReceived);
  }

  _stopListenMessages(castSession, namespaces) {
    castSession.removeMessageListener(namespaces.customChannel, this._onCustomChannelMessageReceived);
  }

  _onCustomChannelMessageReceived = (channel, message) => {
    const messageReceivedEvent = new CastSenderCustomMessageReceivedEvent(channel, message);

    this._logger.log('CustomChannelMessageReceived::', messageReceivedEvent);
    this.dispatchEvent(messageReceivedEvent);
  }

  _onApplicationStatusChanged = ({ status }) => {
    this._logger.log('ApplicationStatusChanged:: ', status);
  }

  _onApplicationMetadataChanged = ({ metadata }) => {
    //metadata.applicationId - string
    //metadata.images - list<Image>
    //metadata.name - string
    //metadata.namespaces - list<string>
    this._logger.log('ApplicationMetadataChanged:: ', metadata);
  }

  _onApplicationActiveInputStateChanged = ({ activeInputState }) => {
    switch (activeInputState) {
      case this._cast.framework.ActiveInputState.ACTIVE_INPUT_STATE_UNKNOWN:
        return this._logger.log('ApplicationActiveInputStateChanged::unknown input');
      case this._cast.framework.ActiveInputState.ACTIVE_INPUT_STATE_NO:
        return this._logger.log('ApplicationActiveInputStateChanged::no input');
      case this._cast.framework.ActiveInputState.ACTIVE_INPUT_STATE_YES:
        return this._logger.log('ApplicationActiveInputStateChanged::has input');
    }
  }

  _onCastSessionVolumeChanged = ({ volume, isMute }) => {
    //volume - [0 - 1]
    this._logger.log('CastSessionVolumeChaged::', volume, isMute);
  }

  _onMediaSessionReceived = ({ mediaSession }) => {
    //https://developers.google.com/cast/docs/reference/web_sender/chrome.cast.media.Media
    this._initMediaSession(mediaSession);
  }

  //https://developers.google.com/cast/docs/reference/chrome/cast.framework.CastStateEventData
  _onCastStateChanged = ({ castState }) => {
    switch (castState) {
      case this._cast.framework.CastState.NO_DEVICES_AVAILABLE:
        this._logger.log('CastStateChanged::NO_DEVICES_AVAILABLE');

        return this.dispatchEvent(new CastSenderNoDevicesEvent());
      case this._cast.framework.CastState.NOT_CONNECTED:
        this._logger.log('CastStateChanged::NOT_CONNECTED');

        return this.dispatchEvent(new CastSenderDevicesAvailableEvent());
      case this._cast.framework.CastState.CONNECTING:
        return this._logger.log('CastStateChanged::CONNECTING');
      case this._cast.framework.CastState.CONNECTED:
        return this._logger.log('CastStateChanged::CONNECTED');
    }
  }

  // https://developers.google.com/cast/docs/reference/chrome/cast.framework.SessionStateEventData
  _onCastSessionStateChanged = ({ session, sessionState, opt_errorCode }) => {
    switch (sessionState) {
      case this._cast.framework.SessionState.NO_SESSION:
        return this._logger.log('CastSessionStateChanged::NO_SESSION');
      case this._cast.framework.SessionState.SESSION_STARTING:
        this._logger.log('CastSessionStateChanged::SESSION_STARTING');

        return this.dispatchEvent(new ConnectingConnectionStateEvent());
      case this._cast.framework.SessionState.SESSION_STARTED:
        this._logger.log('CastSessionStateChanged::SESSION_STARTED', session);

        return this._onNewCastSessionReceived(session);
      case this._cast.framework.SessionState.SESSION_RESUMED:
        this._logger.log('CastSessionStateChanged::SESSION_RESUMED', session);

        return this._onNewCastSessionReceived(session);
      case this._cast.framework.SessionState.SESSION_ENDING:
        this._logger.log('CastSessionStateChanged::SESSION_ENDING', session);

        return this.dispatchEvent(new DisconnectingConnectionStateEvent());
      case this._cast.framework.SessionState.SESSION_ENDED:
        this._logger.log('CastSessionStateChanged::SESSION_ENDED', session);

        return this._onCastSessionEnd(session);
      case this._cast.framework.SessionState.SESSION_START_FAILED:
        return this._logger.error('CastSessionStateChanged::SESSION_START_FAILED', opt_errorCode);
    }
  }

  _onNewCastSessionReceived(session) {
    this._initCastSession(session);
    const device = session.getCastDevice();
    const deviceList = [new CastSenderDevice(device.friendlyName, device.label, true)];

    this.dispatchEvent(new CastSenderDevicesUpdateEvent(deviceList));

    // workaround to prioritize CastSenderDevicesUpdateEvent over connection
    // update event
    setTimeout(() => {
      this.dispatchEvent(new ConnectedConnectionStateEvent());

      if (this._remotePlayer !== null) {
        this.dispatchEvent(new CastSenderVolumeUpdateEvent(this._remotePlayer.volumeLevel));
      }
    }, 0)
  }

  _onCastSessionEnd(session) {
    this._stopListenCastSessionEvents(session, this._cast.framework.SessionEventType);
    this._stopListenMessages(session, { customChannel: this._customChannelNamespace });
    this.dispatchEvent(new DisconnectedConnectionStateEvent());
  }

  _onRemotePlayerMediaInfoChange = ({ value }) => {
    this._logger.log('remoteController::mediaInfoChange', value);

    this._dispatchMediaInfo(value);
  }

  _dispatchMediaInfo(mediaInfo) {
    try {
      const serialized = JSON.stringify(mediaInfo);

      this.dispatchEvent(new CastSenderMediaInformationUpdateEvent(serialized));
    } catch (e) {
      this._logger.log('Failed to encode media info fallback to epmty serialized map: ', e);
      this.dispatchEvent(new CastSenderMediaInformationUpdateEvent('{}'));
    }
  }

  _onRemotePlayerVolumeChange = ({ value }) => {
    this._logger.log('remoteController::volumeChange', value);
    this.dispatchEvent(new CastSenderVolumeUpdateEvent(value));
  }

  _onRemotePlayerCurrentTimeChange = ({ value }) => {
    this._logger.debug('remoteController::currentTimeChange', value);
    this.dispatchEvent(new CastSenderPositionUpdateEvent(value));
  }

  _onRemotePlayerStateChange = ({ value }) => {
    this._logger.log('remoteController::stateChange', value);
    const { IDLE, PLAYING, PAUSED, BUFFERING } = this._chrome.cast.media.PlayerState;

    switch (value) {
      case IDLE: {
        if (this._mediaSession?.media?.customData.isLinearScrubbingManifestChange) {
          this._logger.log('remoteController:: linear scrubbing manifest change - skip IDLE state');

          return;
        }

        return this.dispatchEvent(new IdleStateCastSenderEvent());
      }
      case PLAYING:
        return this.dispatchEvent(new PlayingStateCastSenderEvent());
      case PAUSED:
        return this.dispatchEvent(new PausedStateCastSenderEvent());
      case BUFFERING:
        return this.dispatchEvent(new BufferingStateCastSenderEvent());
    }
  }

  requestSession() {
    this._castContext?.requestSession()
      ?.then(() => this._logger.log('Request session was successfull'))
      ?.catch((err) => {
        this._logger.error('Request session was failed', err);
        this.dispatchEvent(new DisconnectedConnectionStateEvent());
      });
  }

  play() {
    this._remotePlayerController?.playOrPause();
  }

  pause() {
    this._remotePlayerController?.playOrPause();
  }

  stop() {
    this._remotePlayerController?.stop();
  }

  disconnect() {
    this._castContext?.endCurrentSession(true);
  }

  cast(loadRequestData) {
    const { MediaInfo, LoadRequest } = this._chrome.cast.media;
    const mediaInfo = new MediaInfo(loadRequestData.entity, loadRequestData.contentType);

    mediaInfo.entity = loadRequestData.entity;
    mediaInfo.customData = loadRequestData.customData;

    const loadRequest = new LoadRequest(mediaInfo);

    loadRequest.currentTime = loadRequestData.currentTime;

    this._castSession?.loadMedia(loadRequest);
  }

  sendCustomMessage(namespace, message) {
    this._castSession?.sendMessage(namespace, message);
  }

  setPosition(position) {
    if (this._remotePlayer) {
      this._remotePlayer.currentTime = position;
    }

    this._remotePlayerController?.seek();
  }

  setVolume(volume) {
    if (this._remotePlayer) {
      this._remotePlayer.volumeLevel = volume;
    }

    this._remotePlayerController?.setVolumeLevel();
  }

  selectTextTrack(language) {
    if (!this._mediaSession) {
      return;
    }

    const textTracks = this._mediaSession.media.tracks
      .filter((track) => track.type === this._chrome.cast.media.TrackType.TEXT);

    this._editTracksInfo(language, textTracks);
  }

  selectAudioTrack(language) {
    if (!this._mediaSession) {
      return;
    }

    const audioTracks = this._mediaSession.media.tracks
      .filter((track) => track.type === this._chrome.cast.media.TrackType.AUDIO);

    this._editTracksInfo(language, audioTracks);
  }

  _editTracksInfo(language, tracks) {
    // Assume the following:
    // [1, 3, 5] - active track ids list.
    // [{ id: 1, lang: 'en' }, { id: 2, lang: 'fr' }] - audio tracks.
    // [{ id: 3 }] - video tracks.
    // [{ id: 5, lang: 'en' }, { id: 6, lang: 'fr }] - text tracks.
    // Example: in order to switch audio from en to fr we should do the following:
    // [1, 3, 5] -(remove audio ids)-> [3, 5] -(add 'fr' audio id)-> [3, 5, 2]
    this._logger.log('edit tracks info: activeTracksIds: ', this._mediaSession.activeTrackIds);

    const newActiveTracksIds = this._mediaSession.activeTrackIds
      .filter((id) => !tracks.find((track) => id === track.trackId));

    const expectedNewTrack = tracks.find((track) => track.language === language);

    if (expectedNewTrack && expectedNewTrack.trackId) {
      newActiveTracksIds.push(expectedNewTrack.trackId);
    }

    this._logger.log('edit tracks info: new activeTracksIds: ', newActiveTracksIds);

    this._mediaSession.editTracksInfo(
      new this._chrome.cast.media.EditTracksInfoRequest(newActiveTracksIds),
      this._onSuccessEditTracksInfoRequest,
      this._onFailedEditTracksInfoRequest
    );
  }

  _onSuccessEditTracksInfoRequest = () => { }

  _onFailedEditTracksInfoRequest = () => { }
}