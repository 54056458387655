import { CastSenderEvent } from './castSenderEvent';
import { CastSenderEventType } from './eventTypes';

//enum
export const CastSenderConnectionState = {
    Connecting: 'connecting',
    Connected: 'connected',
    Disconnecting: 'disconnecting',
    Disconnected: 'disconnected',
}

export class CastSenderConnectionStateUpdateEvent extends CastSenderEvent {
    constructor() {
        super(CastSenderEventType.ConnectionStateChanged);

        this.state = '';
    }
}

export class ConnectingConnectionStateEvent extends CastSenderConnectionStateUpdateEvent {
    state = CastSenderConnectionState.Connecting;
}

export class ConnectedConnectionStateEvent extends CastSenderConnectionStateUpdateEvent {
    state = CastSenderConnectionState.Connected;
}

export class DisconnectingConnectionStateEvent extends CastSenderConnectionStateUpdateEvent {
    state = CastSenderConnectionState.Disconnecting;
}

export class DisconnectedConnectionStateEvent extends CastSenderConnectionStateUpdateEvent {
    state = CastSenderConnectionState.Disconnected;
}
