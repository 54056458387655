import { CastSenderEvent } from './castSenderEvent';
import { CastSenderEventType } from './eventTypes';

export class CastSenderDevice {
    constructor(name, id, isSelected) {
        this.name = name;
        this.id = id;
        this.isSelected = isSelected;
    }
}

const WEB_MOCK_DEVICE_NAME = 'webmockdevice';
const WEB_MOCK_DEVICE_ID = 'webmockdevice_id';

const WEB_MOCK_DEVICE = new CastSenderDevice(WEB_MOCK_DEVICE_NAME, WEB_MOCK_DEVICE_ID, false);

export class CastSenderDevicesUpdateEvent extends CastSenderEvent {
    constructor(devices) {
        super(CastSenderEventType.DevicesUpdate);

        this.devices = devices;
    }
}

export class CastSenderNoDevicesEvent extends CastSenderDevicesUpdateEvent {
    constructor() {
        super([]);
    }
}

export class CastSenderDevicesAvailableEvent extends CastSenderDevicesUpdateEvent {
    constructor() {
        super([WEB_MOCK_DEVICE]);
    }
}