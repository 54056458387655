import { CastSenderEvent } from './castSenderEvent';
import { CastSenderEventType } from './eventTypes';

export class CastSenderVolumeUpdateEvent extends CastSenderEvent {
  constructor(volume) {
    super(CastSenderEventType.VolumeUpdate);
    this.volume = volume;
  }
}

export class CastSenderPositionUpdateEvent extends CastSenderEvent {
  constructor(position) {
    super(CastSenderEventType.PositionUpdate);
    this.position = position;
  }
}

export class CastSenderMediaInformationUpdateEvent extends CastSenderEvent {
  constructor(mediaInformation) {
    super(CastSenderEventType.MediaInformationUpdate);
    this.mediaInformation = mediaInformation;
  }
}

export class CastSenderCustomMessageReceivedEvent extends CastSenderEvent {
  constructor(namespace, customMessage) {
    super(CastSenderEventType.CustomMessageReceived);
    this.namespace = namespace;
    this.customMessage = customMessage;
  }
}
