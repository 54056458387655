//enum
export const CastSenderEventType = {
  Error: 'CastSenderError',
  PositionUpdate: 'CastSenderTimeUpdate',
  VolumeUpdate: 'CastSenderVolumeUpdate',
  DurationUpdate: 'CastSenderDurationChanged',
  StateUpdate: 'CastSenderStateUpdate',
  TextTracksUpdate: 'CastSenderTextTracksUpdate',
  AudioTracksUpdate: 'CastSenderAudioTracksUpdate',
  DevicesUpdate: 'CastSenderDevicesUpdate',
  ConnectionStateChanged: 'CastSenderConnectionStateChanged',
  MediaInformationUpdate: 'CastSenderMediaInformationUpdate',
  CustomMessageReceived: 'CastSenderCustomMessageReceived',
}
