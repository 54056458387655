import { CastSenderEvent } from './castSenderEvent';

//enum:
export const CastSenderLoggerEventType = {
  LevelChanged: 'levelchanged',
}

export class CastSenderLoggerLevelChagedEvent extends CastSenderEvent {
  constructor(isEnabled) {
    super(CastSenderLoggerEventType.LevelChanged);

    this.isEnabled = isEnabled;
  }
}