import { CastSenderEvent } from './castSenderEvent';
import { CastSenderEventType } from './eventTypes';

//enum
// const CastSenderErrorCode = {}

export class CastSenderErrorEvent extends CastSenderEvent {
  constructor(error) {
    super(CastSenderEventType.Error);

    this.code = -1;
    this.error = error;
  }
}
