import { CastSenderEvent } from './castSenderEvent';
import { CastSenderEventType } from './eventTypes';

//enum
export const CastSenderState = {
  Playing: 'playing',
  Paused: 'paused',
  Buffering: 'buffering',
  Idle: 'idle',
}

export class CastSenderStateUpdateEvent extends CastSenderEvent {
  constructor() {
    super(CastSenderEventType.StateUpdate);

    this.state = '';
  }
}

export class PausedStateCastSenderEvent extends CastSenderStateUpdateEvent {
  state = CastSenderState.Paused;
}

export class PlayingStateCastSenderEvent extends CastSenderStateUpdateEvent {
  state = CastSenderState.Playing;
}

export class BufferingStateCastSenderEvent extends CastSenderStateUpdateEvent {
  state = CastSenderState.Buffering;
}

export class IdleStateCastSenderEvent extends CastSenderStateUpdateEvent {
  state = CastSenderState.Idle;
}
