import { CastSenderEvent } from './castSenderEvent';
import { CastSenderEventType } from './eventTypes';

export class CastSenderTrack {
  constructor(id, language, isActive, role) {
    this.id = String(id);
    this.language = language;
    this.isActive = isActive;
    this.role = role || '';
  }
}

export class CastSenderTextTrack extends CastSenderTrack { 
  constructor(id, language, isActive, role, isHardOfHearing, isAdBreakTrack){
    super(id, language, isActive, role);
    this.customData = JSON.stringify({isHardOfHearing, isAdBreakTrack});
  }
}

export class CastSenderAudioTrack extends CastSenderTrack { 
  constructor(id, language, isActive, role, isAudioDescription, isAdBreakTrack){
    super(id, language, isActive, role);
    this.customData = JSON.stringify({isAudioDescription, isAdBreakTrack});
  }
}


export class CastSenderTextTracksUpdateEvent extends CastSenderEvent {
  constructor(textTracks) {
    super(CastSenderEventType.TextTracksUpdate);

    this.textTracks = textTracks;
  }
}

export class CastSenderAudioTracksUpdateEvent extends CastSenderEvent {
  constructor(audioTracks) {
    super(CastSenderEventType.AudioTracksUpdate);

    this.audioTracks = audioTracks;
  }
}
