import JsLogger from 'js-logger';
import { CastSenderEvent } from './events/castSenderEvent';
import {
  CastSenderConnectionState,
  CastSenderConnectionStateUpdateEvent
} from './events/connectionState';
import {
  CastSenderDevice,
  CastSenderDevicesUpdateEvent
} from './events/devices';
import { CastSenderErrorEvent } from './events/error';
import { CastSenderEventType } from './events/eventTypes';
import { CastSenderState, CastSenderStateUpdateEvent } from './events/state';
import {
  CastSenderAudioTracksUpdateEvent,
  CastSenderTextTracksUpdateEvent,
  CastSenderTrack
} from './events/tracks';
import {
  CastSenderCustomMessageReceivedEvent,
  CastSenderMediaInformationUpdateEvent,
  CastSenderPositionUpdateEvent,
  CastSenderVolumeUpdateEvent
} from './events/video';
import BaseCastSenderLogger from './logger';
import Sender from './sender';

const isAvailablePromise = new Promise((resolve) => {
  window.__onGCastApiAvailable = resolve;
});

class FlutterWebSender extends Sender {
  constructor(options) {
    const senderLogger = new BaseCastSenderLogger({
      window: window,
      source: JsLogger,
      loggerLevelLabel: 'senderloglevel',
      // eslint-disable-next-line no-undef
      defaultLevel: DEFAULT_LOG_LEVEL,
    });

    const mainLogger = senderLogger.forName('Main');

    // eslint-disable-next-line no-undef
    mainLogger.info(`Sender Version: ${VERSION}`);

    super({ logger: senderLogger, customChannelNamespace: options.customChannelNamespace });

    isAvailablePromise.then((isAvailable) => {
      if (isAvailable) {
        mainLogger.info('cast api is ready to be used.');

        this._cast = window.cast;
        this._chrome = window.chrome;

        this._initCastContext(options.receiverApplicationId);
        this._initRemotePlayer();
      } else {
        mainLogger.info('cast api is not available.');
        //dispatch error event
      }
    });

    window.__flutter_web_sender = this;
  }
}

window.WebSenderNamespace = {
  //sender:
  FlutterWebSender,
  //events:
  CastSenderEvent,
  CastSenderVolumeUpdateEvent,
  CastSenderPositionUpdateEvent,
  CastSenderStateUpdateEvent,
  CastSenderErrorEvent,
  CastSenderTextTracksUpdateEvent,
  CastSenderAudioTracksUpdateEvent,
  CastSenderDevicesUpdateEvent,
  CastSenderConnectionStateUpdateEvent,
  CastSenderMediaInformationUpdateEvent,
  CastSenderCustomMessageReceivedEvent,
  //enums:
  CastSenderEventType,
  CastSenderConnectionState,
  CastSenderState,
  //models:
  CastSenderTrack,
  CastSenderDevice,
}