import { CastSenderEventTarget } from './events/castSenderEvent';
import { CastSenderLoggerLevelChagedEvent } from './events/logger';

export default class BaseCastSenderLogger extends CastSenderEventTarget {
  constructor(options) {
    super();

    this._style = 'background: #333; padding: 3px; color: #bada55';
    this._label = options.loggerLevelLabel;
    this._window = options.window;
    this._source = options.source;

    this._source.useDefaults({
      defaultLevel: this._getDefaultLevel(options.defaultLevel),
      formatter: this._formatMessage,
    });

    this._window.addEventListener('hashchange', this._onHashChanged);
    this._onHashChanged();
  }

  get isEnabled() {
    return this._source.getLevel().value !== this._source.OFF.value;
  }

  dispose() {
    this._window.removeEventListener('hashchange', this._onHashChanged);
  }

  forName(name) {
    return this._source.get(name);
  }

  _getDefaultLevel(envLevel) {
    return envLevel === this._source.DEBUG.name
      ? this._source.DEBUG
      : this._source.OFF;
  }

  _onHashChanged = () => {
    this._trackLoggerLevel();
    this.dispatchEvent(new CastSenderLoggerLevelChagedEvent(this.isEnabled));
  }

  _formatMessage = (messages, context) => {
    const name = context.name ? ` * [${context.name}]` : '';

    messages.unshift(this._style);
    messages.unshift(`%c${new Date().toLocaleTimeString()}${name}`);
  }

  _trackLoggerLevel() {
    const { hash } = this._window.location;
    const normalizedHash = hash.replace('#', '');
    const urlSearchParams = new URLSearchParams(normalizedHash);

    const labelValue = urlSearchParams.get(this._label);

    if (!labelValue) {
      return;
    }

    switch (labelValue) {
      case this._source.TRACE.name:
        return this._source.setLevel(this._source.TRACE);//.error .warn .time .info .debug .trace [1]
      case this._source.DEBUG.name:
        return this._source.setLevel(this._source.DEBUG);//.error .warn .time .info .debug [2]
      case this._source.INFO.name:
        return this._source.setLevel(this._source.INFO);//.error .warn .time .info [3]
      case this._source.TIME.name:
        return this._source.setLevel(this._source.TIME);//.error .warn .time [4]
      case this._source.WARN.name:
        return this._source.setLevel(this._source.WARN);//.error .warn [5]
      case this._source.ERROR.name:
        return this._source.setLevel(this._source.ERROR);//.error [8]
      case this._source.OFF.name:
        return this._source.setLevel(this._source.OFF);//nothing [99]
    }
  }
}